import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import safeGetImage from "../utils/safeGetImage"
import CaseStudyArchiveCard from "./CaseStudyArchiveCard"

export default function CaseStudiesByTech({ technology }) {
  const query = useStaticQuery(
    graphql`
      query {
        caseStudies: allMdx(
          filter: { fileAbsolutePath: { regex: "/content/caseStudies/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                techTags
                path
                client
                heroBackgroundImage
                shortDescription
                imageDesktop {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageMobile {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const allCaseStudies = query.caseStudies.edges
  const selectedCaseStudies = []

  //   get relevant case studies
  allCaseStudies.filter(caseStudy => {
    const techTags = caseStudy.node.frontmatter.techTags
    if (techTags && techTags.indexOf(technology) >= 0) {
      return selectedCaseStudies.push(caseStudy)
    }
    return null
  })

  return (
    <section className="case-studies__archive">
      { selectedCaseStudies.map((caseStudy, i) => {
        const {
          title,
          client,
          shortDescription,
          heroBackgroundImage,
          path,
          imageMobile,
          imageDesktop,
        } = caseStudy.node.frontmatter
        const imagePosition = i % 2 === 0 ? "right" : "left"
        return (
          <CaseStudyArchiveCard
            key={ caseStudy.node.id }
            client={ client }
            subtitle={ title }
            blurb={ shortDescription }
            backgroundImage={ `url(${ safeGetImage(heroBackgroundImage) })` }
            path={ path }
            imageMobile={ imageMobile }
            imageDesktop={ imageDesktop }
            imagePosition={ imagePosition }
          />
        )
      }) }
    </section>
  )
}
