import React from "react"
import PropTypes from "prop-types"
import DesktopImageContainer from "../components/DesktopImageContainer"
import MobileImageContainer from "../components/MobileImageContainer"
import TextBlock from "../components/TextBlock"

import Panel from "./core/Panel"

const CaseStudyArchiveCard = ({
  imageMobile,
  imageDesktop,
  client,
  blurb,
  path,
  backgroundImage,
  imagePosition,
}) => {
  // get correct image format to decide on frame
  const image = imageMobile
    ? {
        src: imageMobile,
        title: client,
      }
    : {
        src: imageDesktop,
        title: client,
      }
  const imgType = imageMobile ? "mobile" : "desktop"
  return (
    <Panel
      className="case-study-archive__card"
      color="purple"
      backgroundImage={backgroundImage}
    >
      <div className={`case-study-archive__card__contents--${imagePosition}`}>
        <div className={`case-study-archive__image ${imgType}`}>
          {imageMobile ? (
            <MobileImageContainer image={image} />
          ) : (
            <DesktopImageContainer image={image} />
          )}
        </div>
        <div className={`case-study-archive__text ${imgType}`}>
          <TextBlock
            title={client}
            button={{
              link: path,
              text: "Read More",
              colour: "primary",
              type: "link",
              gatsbyLink: true
            }}
          >{blurb}</TextBlock>
        </div>
      </div>
    </Panel>
  )
}

CaseStudyArchiveCard.propTypes = {
  imageMobile: PropTypes.shape({
    publicURL: PropTypes.string,
  }),
  imageDesktop: PropTypes.shape({
    publicURL: PropTypes.string,
  }),
  client: PropTypes.string,
  blurb: PropTypes.string,
  path: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default CaseStudyArchiveCard
