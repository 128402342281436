import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import HeroCaseStudy from "../components/HeroCaseStudy"
import safeGetImage from "../utils/safeGetImage"
import CaseStudiesByTech from "../components/CaseStudiesByTech"
import ContactForm from "../components/ContactForm"

export default function Technology({ data }) {
  const {
    mdx: { frontmatter },
  } = data
  return (
    <>
      <SEO title={frontmatter.text} />
      <HeroCaseStudy
        icon={frontmatter}
        className={"technologies__hero"}
        title={frontmatter.text}
        text={frontmatter.shortDescription}
        backgroundImage={`url(${safeGetImage(frontmatter.backgroundImage)})`}
      />
      <CaseStudiesByTech technology={frontmatter.text} />
      <ContactForm />
    </>
  )
}

export const pageQuery = graphql`
  query TechnologyByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        text
        shortDescription
        backgroundImage
        icon {
          publicURL
        }
      }
      body
    }
  }
`
